import React from "react";
import GoonoBaseGrid from "@utils/templates/GoonoBaseGrid";
import { Box } from "@material-ui/core";
import MainVisualSection from "./Commons/MainVisualSection";
import MainServiceListSection from "./Commons/MainServiceListSection";
import MainCustomersSection from "./Commons/MainCustomersSection";
import MainUseCaseSection from "./Commons/MainUseCaseSection";
import MainReviewSection from "./Commons/MainReviewSection";
import MainBanner from "./Commons/MainBanner";
import SEOMeta from "../common/SEOMeta";
import {
  useMainSlideController,
  MainSlideController,
} from "./hooks/useMainSlideController";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export type GoonoService = "research-note" | "data-room";

type ContextType = {
  mainSlideController: MainSlideController;
};

export const HomeScreenContext = React.createContext<ContextType>(
  {} as ContextType
);

export default function HomeScreen() {
  const slider = React.createRef<Slider>();
  const mainSlideController = useMainSlideController({ slideRef: slider });

  return (
    <HomeScreenContext.Provider
      value={{
        mainSlideController,
      }}
    >
      <SEOMeta
        title="쉽고 빠른 전자연구노트, 구노 | 스마트한 문서 보안/공유 솔루션, 스트롱박스"
        description="연구자료는 프라이빗하게 보관하고, 기밀자료는 자유롭게 공유하세요! ISO, GS 인증 등 국내외 표준을 충족하는 서비스로 국내 2,000곳 이상의 고객사에 최고의 기술을 제공합니다."
      />
      <GoonoBaseGrid viewerOption={{ type: "landing_page" }}>
        <Box style={{ position: "relative", width: "100%" }}>
          <MainVisualSection />
          <MainServiceListSection />
          <MainCustomersSection />
          <MainUseCaseSection />
          <MainReviewSection />
          <MainBanner />
        </Box>
      </GoonoBaseGrid>
    </HomeScreenContext.Provider>
  );
}
