import React from "react";
import { Box } from "@material-ui/core";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import useHomeStyles from "../styles";
import ServiceListSectionItem, {
  ServiceListItem,
} from "./ServiceListSectionItem";
import { ICONS } from "@assets/images/public";
import { ScreenURL } from "src/routes/route_list";
import clsx from "clsx";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";

import ServiceImg01_01 from "@assets/images/public/screen/HomeScreen/content-image/service-01_01.png";
import ServiceImg01_02 from "@assets/images/public/screen/HomeScreen/content-image/service-01_02.png";
import ServiceImg01_03 from "@assets/images/public/screen/HomeScreen/content-image/service-01_03.png";
import ServiceImg02_01 from "@assets/images/public/screen/HomeScreen/content-image/service-02_01.png";
import ServiceImg02_02 from "@assets/images/public/screen/HomeScreen/content-image/service-02_02.png";
import ServiceImg02_03 from "@assets/images/public/screen/HomeScreen/content-image/service-02_03.png";
import ServiceImg03_01 from "@assets/images/public/screen/HomeScreen/content-image/service-03_01.png";
import ServiceImg03_02 from "@assets/images/public/screen/HomeScreen/content-image/service-03_02.png";
import ServiceImg03_03 from "@assets/images/public/screen/HomeScreen/content-image/service-03_03.png";

export default function MainServiceListSection() {
  const classes = useHomeStyles();
  const [currentSection, setCurrentSection] = React.useState<number>(0);

  const ServiceSectionArray: ServiceListItem[] = [
    {
      badgeTitle: "연구노트를 가장 쉽고 빠르게",
      title: "전자연구노트 구노",
      linkUrl: ScreenURL.SERVICE_GOONO,
      gridItems: <GridItemGoono />,
    },
    {
      badgeTitle: "연구기관의 디지털 혁신을 완성하는",
      title: "구축형 전자연구노트",
      linkUrl: ScreenURL.SERVICE_BUILTIN,
      gridItems: <GridItemBuiltIn />,
    },
    {
      badgeTitle: "철저한 보안, 스마트한 공유",
      title: "문서보안공유 솔루션 \n 스트롱박스",
      linkUrl: ScreenURL.SERVICE_STRONGBOX,
      gridItems: <GridItemStrongBox />,
    },
  ];

  const handleScroll = () => {
    const scrollBottom = window.scrollY + window.innerHeight * 0.65;

    const sectionOffsets = ServiceSectionArray.map((_section, index) => {
      const el = document.getElementById(`scrollPoint-${index + 1}`);
      return el?.offsetTop ?? 0;
    });

    /** 현재 스크롤 위치를 기준으로 어떤 섹션이 보이는지 확인 */
    const visibleSectionIndex = sectionOffsets.findIndex(
      (offset) => scrollBottom < offset
    );
    setCurrentSection(
      visibleSectionIndex === -1 ? sectionOffsets.length : visibleSectionIndex
    );
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box className={classes.service_section_list}>
      {ServiceSectionArray.map((item, index) => {
        const sectionNumber = index + 1;
        return (
          <ServiceListSectionItem
            key={item.title}
            {...item}
            id={`scrollPoint-${sectionNumber}`}
            active={
              currentSection === sectionNumber || currentSection > sectionNumber
            }
          />
        );
      })}
    </Box>
  );
}

function GridItemGoono() {
  return (
    <React.Fragment>
      <Box
        className={clsx("content-grid-item", "type-01")}
        style={{ background: "#edf0f2" }}
      >
        <Box className="grid-item-title-box">
          <GoonoTypography
            className="grid-item-title"
            children={"쉽고 빠른 연구노트 작성"}
          />
          <GoonoTypography
            className="grid-item-desc"
            children={"PC, 모바일 모두 언제 어디서든 편하고 빠르게"}
          />
        </Box>
        <Box className="grid-item-img-box">
          <img src={ServiceImg01_01} alt="" />
        </Box>
      </Box>
      <Box
        className={clsx("content-grid-item", "type-02")}
        style={{ background: "#222222", color: "#fff" }}
      >
        <Box className="grid-item-title-box">
          <GoonoTypography
            className="grid-item-title"
            children={"안전하고 신속한 외부 연동"}
          />
          <GoonoTypography
            className="grid-item-desc"
            children={"깃허브, 구글 드라이브 연동으로 보다 편리하게"}
          />
        </Box>
        <Box className="grid-item-img-box">
          <img src={ServiceImg01_02} alt="" />
        </Box>
      </Box>
      <Box
        className={clsx("content-grid-item", "span2", "type-03")}
        style={{ background: "#E9F3FF" }}
      >
        <Box className="grid-item-img-box">
          <img src={ServiceImg01_03} alt="" />
        </Box>
        <Box className="grid-item-title-box">
          <GoonoTypography
            className="grid-item-title"
            children={"국가연구개발사업 \n 연구노트 지침법령 충족"}
          />
          <Box className="grid-item-desc-list">
            <GoonoTypography
              className="grid-item-desc"
              children={
                <>
                  <ICONS.sign />
                  기록자의 전자 서명인증
                </>
              }
            />
            <GoonoTypography
              className="grid-item-desc"
              children={
                <>
                  <ICONS.time />
                  기록 날짜와 시각 자동기록
                </>
              }
            />
            <GoonoTypography
              className="grid-item-desc"
              children={
                <>
                  <ICONS.save />
                  기록의 위변조 확인 가능
                </>
              }
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

function GridItemBuiltIn() {
  return (
    <React.Fragment>
      <Box
        className={clsx("content-grid-item", "span2", "type-04")}
        style={{ background: "#200E48", color: "#fff" }}
      >
        <Box className="grid-item-title-box">
          <GoonoTypography
            className="grid-item-title"
            children={"완벽한 보안과 유연성"}
          />
          <Box className="grid-item-desc-list">
            <GoonoTypography
              className="grid-item-desc"
              children={
                <>
                  <DoneRoundedIcon />
                  엔터프라이즈급 보안 및 규정 준수
                </>
              }
            />
            <GoonoTypography
              className="grid-item-desc"
              children={
                <>
                  <DoneRoundedIcon />
                  GxP, 21 CFR Part 11 등 데이터 표준 완벽 대응
                </>
              }
            />
            <GoonoTypography
              className="grid-item-desc"
              children={
                <>
                  <DoneRoundedIcon />
                  블록체인, 분산 클라우드 기술을 통한 위변조 방지
                </>
              }
            />
            <GoonoTypography
              className="grid-item-desc"
              children={
                <>
                  <DoneRoundedIcon />
                  NarDa, DataWorks 등 연구데이터 레포지터리 연동
                </>
              }
            />
            <GoonoTypography
              className="grid-item-desc"
              children={
                <>
                  <DoneRoundedIcon />
                  eGovFramework 기반 시스템, ISO 27001/GS 인증
                </>
              }
            />
          </Box>
        </Box>
        <Box className="grid-item-img-box">
          <img src={ServiceImg02_01} alt="" />
        </Box>
      </Box>
      <Box
        className={clsx("content-grid-item", "type-05")}
        style={{ background: "#2C2E8C" }}
      >
        <Box className="grid-item-title-box">
          <GoonoTypography
            className="grid-item-title"
            children={"대규모 프로젝트에도 문제없는"}
          />
          <GoonoTypography
            className="grid-item-desc"
            children={"책임자를 위해 더욱 강화된 관리 및 모니터링 기능"}
          />
        </Box>
        <Box className="grid-item-img-box">
          <img src={ServiceImg02_02} alt="" />
        </Box>
      </Box>
      <Box
        className={clsx("content-grid-item", "type-06")}
        style={{ background: "#D5D8F5" }}
      >
        <Box className="grid-item-title-box">
          <GoonoTypography
            className="grid-item-title"
            children={"연구기관에 최적화된 사용성"}
          />
          <GoonoTypography
            className="grid-item-desc"
            children={"연구자와 관리자를 모두 고려한 사용자 중심 워크플로우"}
          />
        </Box>
        <Box className="grid-item-img-box">
          <img src={ServiceImg02_03} alt="" />
        </Box>
      </Box>
    </React.Fragment>
  );
}

function GridItemStrongBox() {
  return (
    <React.Fragment>
      <Box
        className={clsx("content-grid-item", "type-07")}
        style={{ background: "#E0F1F2" }}
      >
        <Box className="grid-item-title-box">
          <GoonoTypography
            className="grid-item-title"
            children={"세상 가장 쉬운 원본 보안"}
          />
          <GoonoTypography
            className="grid-item-desc"
            children={"강력한 보안 기술로 문서를 가장 안전하게!"}
          />
        </Box>
        <Box className="grid-item-img-box">
          <img src={ServiceImg03_01} alt="" />
        </Box>
      </Box>
      <Box
        className={clsx("content-grid-item", "type-07")}
        style={{ background: "#212B20" }}
      >
        <Box className="grid-item-title-box">
          <GoonoTypography
            className="grid-item-title"
            children={"원본 파일 공유는 이제 그만"}
          />
          <GoonoTypography
            className="grid-item-desc"
            children={"맞춤 공유 옵션 설정, 링크 무제한 생성 가능"}
          />
        </Box>
        <Box className="grid-item-img-box">
          <img src={ServiceImg03_02} alt="" />
        </Box>
      </Box>
      <Box
        className={clsx("content-grid-item", "span2", "type-08")}
        style={{ background: "#F0F2F6" }}
      >
        <Box className="grid-item-title-box">
          <GoonoTypography
            className="grid-item-title"
            children={"정말로 필요했던 인사이트"}
          />
          <GoonoTypography
            className="grid-item-desc"
            preLine
            children={
              "누가 문서를 열람했는지, 어떤 내용에 관심을 가지고 \n 있는지 인사이트를 통해 확인할 수 있어요."
            }
          />
        </Box>
        <Box className="grid-item-img-box">
          <img src={ServiceImg03_03} alt="" />
        </Box>
      </Box>
    </React.Fragment>
  );
}
