import Iso9001Img from "@assets/images/public/screen/HomeScreen/certified-mark/img-iso9001.png";
import Iso27001Img from "@assets/images/public/screen/HomeScreen/certified-mark/img-iso27001.png";
import CrosscertImg from "@assets/images/public/screen/HomeScreen/certified-mark/img-crosscert.png";
import GoodswImg from "@assets/images/public/screen/HomeScreen/certified-mark/img-goodsoftware.png";
import FdaImg from "@assets/images/public/screen/HomeScreen/certified-mark/img-fda.png";

import LogoImmuno from "@assets/images/public/screen/HomeScreen/review/logo-immuno.png";
import LogoIae from "@assets/images/public/screen/HomeScreen/review/logo-iae.png";
import LogoIntek from "@assets/images/public/screen/HomeScreen/review/logo-intek.png";
import LogoSangji from "@assets/images/public/screen/HomeScreen/review/logo-sang.png";
import LogoNxv from "@assets/images/public/screen/HomeScreen/review/logo-nxv.png";
import LogoEwha from "@assets/images/public/screen/HomeScreen/review/lgogo-ewha.png";
import LogoRoot from "@assets/images/public/screen/HomeScreen/review/logo-root.png";

import MainVisual from "@assets/images/public/screen/HomeScreen/main-visual/main-img.png";
import MainVisualLazy from "@assets/images/public/screen/HomeScreen/main-visual/main-img-lazy.jpeg";
import MainBg from "@assets/images/public/screen/HomeScreen/main-visual/main-bg.png";

import { ReactComponent as ItemImg01 } from "@assets/images/public/screen/productBuiltinServiceScreen/item-img-01.svg";
import { ReactComponent as ItemImg02 } from "@assets/images/public/screen/productBuiltinServiceScreen/item-img-02.svg";
import { ReactComponent as ItemImg03 } from "@assets/images/public/screen/productBuiltinServiceScreen/item-img-03.svg";
import { ReactComponent as ItemImg04 } from "@assets/images/public/screen/productBuiltinServiceScreen/item-img-04.svg";
import { ReactComponent as ItemImg05 } from "@assets/images/public/screen/productBuiltinServiceScreen/item-img-05.svg";

import IntroSectionBg from "@assets/images/public/screen/productDataRoomServiceScreen/intro-bg.png";

import { ReactComponent as ProductGoonoIcon } from "@assets/images/public/icon/icon-product_goono.svg";
import { ReactComponent as ProductBuiltinIcon } from "@assets/images/public/icon/icon-product_builtin.svg";
import { ReactComponent as ProductStrongboxIcon } from "@assets/images/public/icon/icon-product_strongbox.svg";

import { ReactComponent as UseCase01Icon } from "@assets/images/public/icon/icon-usecase_01.svg";
import { ReactComponent as UseCase02Icon } from "@assets/images/public/icon/icon-usecase_02.svg";
import { ReactComponent as UseCase03Icon } from "@assets/images/public/icon/icon-usecase_03.svg";
import { ReactComponent as UseCase04Icon } from "@assets/images/public/icon/icon-usecase_04.svg";

import Customer01 from "@assets/images/public/screen/HomeScreen/content-icon/customer_01.png";
import Customer02 from "@assets/images/public/screen/HomeScreen/content-icon/customer_02.png";
import Customer03 from "@assets/images/public/screen/HomeScreen/content-icon/customer_03.png";

import { ReactComponent as UseCaseIcon01 } from "@assets/images/public/screen/HomeScreen/content-icon/use-case_01.svg";
import { ReactComponent as UseCaseIcon02 } from "@assets/images/public/screen/HomeScreen/content-icon/use-case_02.svg";
import { ReactComponent as UseCaseIcon03 } from "@assets/images/public/screen/HomeScreen/content-icon/use-case_03.svg";
import { ReactComponent as UseCaseIcon04 } from "@assets/images/public/screen/HomeScreen/content-icon/use-case_04.svg";

import UseCaseImg01 from "@assets/images/public/screen/HomeScreen/content-image/use-caes-img_01.png";
import UseCaseImg02 from "@assets/images/public/screen/HomeScreen/content-image/use-caes-img_02.png";
import UseCaseImg03 from "@assets/images/public/screen/HomeScreen/content-image/use-caes-img_03.png";

import Reviewer01 from "@assets/images/public/screen/HomeScreen/review/review-profile_01.png";
import Reviewer02 from "@assets/images/public/screen/HomeScreen/review/review-profile_02.png";
import Reviewer03 from "@assets/images/public/screen/HomeScreen/review/review-profile_03.png";

export const IMAGES = {
  header: {
    productGoono: ProductGoonoIcon,
    productBuiltin: ProductBuiltinIcon,
    productStrongbox: ProductStrongboxIcon,
    useCase: {
      useCase01: UseCase01Icon,
      useCase02: UseCase02Icon,
      useCase03: UseCase03Icon,
      useCase04: UseCase04Icon,
    },
  },
  HomeScreen: {
    certifiedMark: {
      iso9001: Iso9001Img,
      iso27001: Iso27001Img,
      crosscert: CrosscertImg,
      goodsw: GoodswImg,
      fda: FdaImg,
    },
    customers: {
      customer01: Customer01,
      customer02: Customer02,
      customer03: Customer03,
    },
    useCase: {
      useCaseIcon01: UseCaseIcon01,
      useCaseIcon02: UseCaseIcon02,
      useCaseIcon03: UseCaseIcon03,
      useCaseIcon04: UseCaseIcon04,
      useCaseImg01: UseCaseImg01,
      useCaseImg02: UseCaseImg02,
      useCaseImg03: UseCaseImg03,
    },
    review: {
      logoImmuno: LogoImmuno,
      logoIae: LogoIae,
      logoIntek: LogoIntek,
      logoSangji: LogoSangji,
      logoNxv: LogoNxv,
      logoEwha: LogoEwha,
      logoRoot: LogoRoot,
      reviewer01: Reviewer01,
      reviewer02: Reviewer02,
      reviewer03: Reviewer03,
    },

    mainVisual: {
      mainVisual: MainVisual,
      mainVisualLazy: MainVisualLazy,
      mainBg: MainBg,
    },
  },
  productBuiltinServiceScreen: {
    itemImg: {
      itemImg01: ItemImg01,
      itemImg02: ItemImg02,
      itemImg03: ItemImg03,
      itemImg04: ItemImg04,
      itemImg05: ItemImg05,
    },
  },
  productDataRoomServiceScreen: {
    introBg: IntroSectionBg,
  },
};

import { ReactComponent as NoteIcon } from "@assets/images/public/icon/icon-note.svg";
import { ReactComponent as StrongBoxIcon } from "@assets/images/public/icon/strong-box.svg";

import { ReactComponent as SignIcon } from "@assets/images/public/screen/HomeScreen/content-icon/icon-sign.svg";
import { ReactComponent as TimeIcon } from "@assets/images/public/screen/HomeScreen/content-icon/icon-time.svg";
import { ReactComponent as SaveIcon } from "@assets/images/public/screen/HomeScreen/content-icon/icon-save.svg";

import { ReactComponent as DriveIcon } from "@assets/images/public/screen/HomeScreen/content-icon/icon-drive.svg";
import { ReactComponent as GithubIcon } from "@assets/images/public/screen/HomeScreen/content-icon/icon-github.svg";

import { ReactComponent as PersonIcon } from "@assets/images/public/screen/HomeScreen/content-icon/icon-person.svg";
import { ReactComponent as FolderIcon } from "@assets/images/public/screen/HomeScreen/content-icon/icon-folder.svg";

import { ReactComponent as CameraIcon } from "@assets/images/public/screen/HomeScreen/content-icon/icon-camera.svg";
import { ReactComponent as ResposiveIcon } from "@assets/images/public/screen/HomeScreen/content-icon/icon-responsive.svg";
import { ReactComponent as LandingNoteIcon } from "@assets/images/public/screen/HomeScreen/content-icon/icon-note.svg";

import { ReactComponent as PostIcon } from "@assets/images/public/icon/icon-post.svg";
import { ReactComponent as SearchIcon } from "@assets/images/public/icon/icon-search.svg";
import { ReactComponent as ShareIcon } from "@assets/images/public/icon/icon-share.svg";
import { ReactComponent as EastIcon } from "@assets/images/public/icon/icon-east.svg";

export const ICONS = {
  note: NoteIcon,
  strongBox: StrongBoxIcon,
  sign: SignIcon,
  time: TimeIcon,
  save: SaveIcon,
  drive: DriveIcon,
  github: GithubIcon,
  person: PersonIcon,
  folder: FolderIcon,
  camera: CameraIcon,
  responsive: ResposiveIcon,
  landingNote: LandingNoteIcon,
  post: PostIcon,
  search: SearchIcon,
  share: ShareIcon,
  east: EastIcon,
};

import { ReactComponent as LogoSm } from "@assets/images/public/logo/logo-goono_sm.svg";

import { ReactComponent as BlogIcon } from "@assets/images/public/screen/HomeScreen/footer/logo-blog.svg";
import { ReactComponent as InstaIcon } from "@assets/images/public/screen/HomeScreen/footer/logo-insta.svg";
import { ReactComponent as YoutubeIcon } from "@assets/images/public/screen/HomeScreen/footer/logo-youtube.svg";
import { ReactComponent as LinkedInIcon } from "@assets/images/public/screen/HomeScreen/footer/logo-linked.svg";

export const LOGO = {
  logoSm: LogoSm,
  sns: {
    blog: BlogIcon,
    insta: InstaIcon,
    youtube: YoutubeIcon,
    linked: LinkedInIcon,
  },
};
