import React from "react";
import { Box, Container, Divider } from "@material-ui/core";

import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import Flex from "@ui/goono/components/layouts/Flex";
import useHomeStyles from "../styles";

import { IMAGES } from "@assets/images/public";

export default function MainCustomersSection() {
  const classes = useHomeStyles();
  const customersData: CustomerItemProps[] = [
    {
      imgSrc: IMAGES.HomeScreen.customers.customer01,
      number: 2000,
      unit: "+",
      text: "고객사 수",
    },
    {
      imgSrc: IMAGES.HomeScreen.customers.customer02,
      number: 30,
      unit: "만+",
      text: "유저 수",
    },
    {
      imgSrc: IMAGES.HomeScreen.customers.customer03,
      number: 4.6,
      unit: "",
      text: "만족도",
    },
  ];

  const [isVisible, setIsVisible] = React.useState(false);

  const handleScroll = () => {
    const scrollBottom = window.scrollY - window.innerHeight * 0.2;
    const el = document.getElementById("customers-section");
    const sectionOffsets = el?.getBoundingClientRect()?.top ?? 0;

    if (scrollBottom >= sectionOffsets + window.innerHeight && !isVisible) {
      setIsVisible(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const number1 = useCountUp(isVisible ? 2000 : 0, 0, 1000, 0);
  const number2 = useCountUp(isVisible ? 30 : 0, 0, 1000, 0);
  const number3 = useCountUp(isVisible ? 4.6 : 0, 0, 1000, 1);

  const animatedCustomersData = customersData.map((item, i) => ({
    ...item,
    number: Number([number1, number2, number3][i]),
  }));

  return (
    <Box className={classes.customers_section}>
      <Container className={classes.container}>
        <Flex
          container
          className={classes.customers_section_inner}
          id="customers-section"
        >
          <Box>
            <GoonoTypography children="Customers" className="sub-title" />
            <GoonoTypography
              className="title"
              type="h2"
              children={"고객사와 함께\n성장하고 있습니다."}
              preLine
            />
          </Box>
          <Box className={classes.customers_box}>
            {animatedCustomersData.map((item, index) => (
              <React.Fragment key={index}>
                <CustomerItem {...item} />
                {index < 2 && <Divider orientation="vertical" />}
              </React.Fragment>
            ))}
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}

type CustomerItemProps = {
  imgSrc: string;
  number: number;
  text: string;
  unit: string;
};

function CustomerItem({ imgSrc, number, text, unit }: CustomerItemProps) {
  const classes = useHomeStyles();
  return (
    <Box className={classes.customers_box_item}>
      <Flex container className={"customers_box_item_img"}>
        <img src={imgSrc} alt={text} />
      </Flex>
      <GoonoTypography
        children={`${number.toLocaleString()}${unit}`}
        className="customers_box_item_number"
      />
      <GoonoTypography children={text} className="customers_box_item_text" />
    </Box>
  );
}

function useCountUp(
  end: number,
  start: number = 0,
  duration: number = 1000,
  toFix: number = 0
): string {
  const [count, setCount] = React.useState(start);
  const increment = (end - start) / ((duration / 1000) * 60); // 초당 증가량 계산

  React.useEffect(() => {
    let currentNum = start;
    let animationFrameId: number;

    const updateCount = () => {
      if (currentNum < end) {
        currentNum += increment;
        if (currentNum > end) currentNum = end; // 목표 초과 방지
        setCount(currentNum);
        animationFrameId = requestAnimationFrame(updateCount); // 다음 프레임 요청
      }
    };

    updateCount();

    return () => {
      cancelAnimationFrame(animationFrameId); // 언마운트 시 애니메이션 프레임 취소
    };
  }, [end, start, duration, increment]);

  return count.toFixed(toFix);
}
