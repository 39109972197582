import React from "react";
import { Box, Container, ButtonBase } from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import useHomeStyles from "../styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { ScreenURL } from "src/routes/route_list";

export type ServiceListItem = {
  badgeTitle: string;
  title: string;
  linkUrl: string;
  gridItems: React.ReactNode;
};

type ServiceListItemProps = {
  id: string;
  active: boolean;
} & ServiceListItem;

export default function ServiceListSectionItem({
  badgeTitle,
  title,
  linkUrl,
  gridItems,
  id,
  active,
}: ServiceListItemProps) {
  const classes = useHomeStyles();
  const history = useHistory();

  const rendeTitleBox = () => {
    return (
      <Box className="service-item-title-box">
        <Box>
          <Box className="service-item-badge">{badgeTitle}</Box>
          <GoonoTypography type="h2" className="service-item-title" keepAll>
            {title}
          </GoonoTypography>
        </Box>

        <Flex container className="service-item-button-box">
          <ButtonBase
            className="service-item-button"
            onClick={() => {
              history.push(linkUrl);
            }}
          >
            <span>더 많은 기능 확인하기</span>
          </ButtonBase>
          <ButtonBase
            className="service-item-button"
            onClick={() => {
              history.push(ScreenURL.CONTACT);
            }}
          >
            <span>문의하기</span>
          </ButtonBase>
        </Flex>
      </Box>
    );
  };

  const renderContentGrid = () => {
    return <Box className={clsx("setvice-item-content-grid")}>{gridItems}</Box>;
  };

  return (
    <Box id={id} className={clsx(classes.service_list_item_wrap)}>
      <Container className={classes.container}>
        <Box
          className={clsx(
            classes.service_item_inner,
            active && "service_item_inner_active"
          )}
        >
          {rendeTitleBox()}
          {renderContentGrid()}
        </Box>
      </Container>
    </Box>
  );
}
