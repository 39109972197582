import { makeStyles, Theme, alpha } from "@material-ui/core";
import { theme, service, system, typo, ect } from "@theme";
import { landingTheme } from "src/styles/landingTheme";
import EastIcon from "@assets/images/public/icon/icon-east.svg";
import BannerBook from "@assets/images/public/screen/HomeScreen/content-image/banner-book.png";
import BannerLock from "@assets/images/public/screen/HomeScreen/content-image/banner-lock.png";

const useHomeStyles = makeStyles((MuiTheme: Theme) => ({
  container: {
    position: "relative",
    ...landingTheme.layout.MAX_CONTAINER,
  },
  visual_section: {
    padding: "0 40px",
    "& .rolling_box": {
      marginTop: 40,
      width: "100%",
    },
  },
  visual_section_inner: {
    margin: "0 auto",
    minHeight: 540,
    maxWidth: 1840,
    borderRadius: "20px",
    background: "linear-gradient(110deg, #3578EA -9.04%, #2363CE 102.04%)",

    display: "flex",
    alignItems: "center",
    color: theme.white,
    "& img": { maxWidth: "100%" },

    "& $container": {
      display: "flex",
      alignItems: "center",
    },

    "& .visual_section_inner_box": {
      marginRight: "auto",
    },

    "& .visual_section_inner_obj_box": { maxWidth: 340 },

    "& .visual_section_inner_title": {
      fontSize: 46,
      fontWeight: 400,
      lineHeight: 1.38,
      letterSpacing: "-1.38px",

      "& strong": {
        fontWeight: 700,
      },
    },
    "& .visual_section_inner_desc": {
      marginTop: 24,
      fontSize: 20,
      opacity: 0.8,
    },
    "& .visual_section_button": {
      marginTop: 60,
      width: "180px",
      height: "60px",
      padding: "8px 16px 8px 24px",

      gap: "8px",
      justifyContent: "space-between",
      borderRadius: "8px",
      background: theme.black,
      color: theme.white,
      fontSize: 20,
      ...theme.font_bold,

      "&:hover": {
        color: theme.black,
        background: theme.white,
      },
    },
  },

  service_section_list: {
    width: "100%",
  },

  service_list_item_wrap: {
    overflow: "hidden",
    paddingTop: 80,
    paddingBottom: 120,

    "&:nth-child(1)": {
      "& .service-item-badge": {
        background: service.primary_50,
        color: service.primary_600,
      },
    },
    "&:nth-child(2)": {
      background: "#F5F6FF",
      "& .service-item-badge": {
        background: ect.deep_purple_50,
        color: ect.deep_purple_800,
      },
      "& .service-item-button": {
        "&::before": { background: theme.white },
        "&::after": { color: theme.gray_9_title },
      },
    },
    "&:nth-child(3)": {
      "& .service-item-badge": {
        background: system.success_50,
        color: system.success_800,
      },
    },
  },

  "@keyframes functionScrollani": {
    "0%": {
      transform: "translateY(50%)",
      opacity: 0,
    },
    "30%": { opacity: 0 },
    "100%": { transform: "translateY(0)", opacity: 1 },
  },

  service_item_inner: {
    "& .service-item-title-box": {
      width: "100%",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },

    "& .service-item-badge": {
      marginBottom: 12,
      height: 44,
      padding: "0 14px",
      borderRadius: 4,
      ...typo.b1_b,
      fontSize: "1.2rem",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
    },

    "& .service-item-title": {
      color: theme.gray_9_title,
      fontSize: 45,
      letterSpacing: -1.35,
      lineHeight: 1.3,
      ...theme.font_bold,
    },

    "& .service-item-button-box": {
      gap: 32,
    },
    "& .service-item-button": {
      gap: 12,
      paddingRight: 32 + 12,
      color: theme.gray_7_text,
      ...typo.b2_m,
      position: "relative",

      "&::before": {
        content: "''",
        position: "absolute",
        top: "50%",
        right: 0,
        width: 32,
        height: 32,
        transform: "translateY(-50%)",
        background: theme.gray_1,
        borderRadius: "50%",
      },
      "&::after": {
        content: "''",
        position: "absolute",
        top: "50%",
        right: 8,
        width: 16,
        height: 16,
        transform: "translateY(-50%)",
        transition: "0.45s cubic-bezier(.785,.135,.15,.86)",
        backgroundColor: theme.gray_7_text,
        maskImage: `url(${EastIcon})`,
        maskSize: "16px auto",
        maskPosition: "left top",
        maskRepeat: "repeat-x",
      },
      "&:hover::after": {
        backgroundPosition: "16px top",
        maskPosition: "16px top",
      },
    },
    "& .setvice-item-content-grid": {
      marginTop: 40,
      display: "grid",
      gap: 24,
      gridTemplateColumns: "1fr 1fr",
    },
    "& .grid-item-title": {
      fontSize: 30,
      ...theme.font_bold,
      letterSpacing: -0.9,
      color: theme.gray_9_title,
      lineHeight: 1.4,
    },
    "& .grid-item-desc-list": {
      marginTop: 40,
      display: "flex",
      flexDirection: "column",
      gap: 28,

      "& .grid-item-desc": {
        display: "flex",
        alignItems: "center",
        gap: 16,
        wordBreak: "keep-all !important",
      },
      "& svg": { width: 32, height: 32 },
    },
    "& .grid-item-desc": {
      marginTop: 10,
      fontSize: 20,
      color: theme.gray_7_text,
      letterSpacing: -1.2,
      ...theme.font_medium,
    },
    "& .content-grid-item": {
      borderRadius: 20,
      position: "relative",
      display: "flex",
      overflow: "hidden",
      transform: "translateY(50%)",
      opacity: 0,

      "&:nth-of-type(2)": { animationDelay: "0.25s" },
      "&:nth-of-type(3)": { animationDelay: "0.5s" },

      "& .grid-item-img-box": { ...theme.flex_center },
      "& .grid-item-img-box img, & .grid-item-img-box object": {
        maxWidth: "100%",
        height: "auto",
      },
      "&.span2": {
        gridColumn: "1/3",
      },

      "&.type-01": {
        flexDirection: "column",

        "& .grid-item-title-box": {
          paddingTop: 46,
          paddingLeft: 46,
          marginBottom: "auto",
        },
        "& .grid-item-title": { color: theme.gray_8 },
        "& .grid-item-desc": { color: theme.gray_6 },
        "& .grid-item-img-box": {
          marginLeft: "auto",
          marginTop: 20,
          "& img": { width: 449 },
        },
      },
      "&.type-02": {
        flexDirection: "column",

        "& .grid-item-title-box": {
          paddingTop: 46,
          paddingLeft: 46,
          marginBottom: "auto",
        },
        "& .grid-item-title": { color: theme.white },
        "& .grid-item-desc": { color: theme.white },
        "& .grid-item-img-box": {
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: -20,
          "& img": { width: 513 },
        },
      },
      "&.type-03": {
        minHeight: 466,
        alignItems: "center",
        paddingLeft: 30,

        "& .grid-item-img-box": {
          marginTop: "auto",
          "& img": { width: 738 },
        },
        "& .grid-item-title-box": {
          margin: "auto",
          paddingRight: 20,
          flexShrink: 0,
        },
        "& .grid-item-title": { whiteSpace: "pre-line" },
        "& .grid-item-desc": { margin: 0 },
      },
      "&.type-04": {
        minHeight: 466,
        alignItems: "center",
        paddingLeft: 46,
        paddingRight: 46,

        "& .grid-item-img-box": { marginRight: 20, "& img": { width: 632 } },
        "& .grid-item-title-box": { marginRight: "auto" },
        "& .grid-item-title": { whiteSpace: "pre-line", color: theme.white },
        "& .grid-item-desc-list": {
          marginTop: 26,
          gap: 16,
          "& svg": { width: 24, height: 24 },
        },
        "& .grid-item-desc": {
          margin: 0,
          color: theme.white,
          fontSize: 18,
          gap: 16,
          opacity: "0.9",
        },
      },
      "&.type-05": {
        flexDirection: "column",

        "& .grid-item-title-box": {
          paddingTop: 46,
          paddingLeft: 46,
          marginBottom: "auto",
        },
        "& .grid-item-title": { color: theme.white },
        "& .grid-item-desc": { color: theme.gray_4 },
        "& .grid-item-img-box": {
          marginRight: "auto",
          marginTop: 50,
          marginLeft: -2,
          marginBottom: -2,
          "& img": { width: 561 },
        },
      },
      "&.type-06": {
        flexDirection: "column",

        "& .grid-item-title-box": {
          paddingTop: 46,
          paddingLeft: 46,
          marginBottom: "auto",
        },
        "& .grid-item-title": { color: theme.gray_9_title },
        "& .grid-item-desc": { color: theme.gray_7_text },
        "& .grid-item-img-box": {
          marginLeft: "auto",
          "& img": { width: 536 },
        },
      },
      "&.type-07": {
        flexDirection: "column",

        "& .grid-item-title-box": {
          padding: 46,
        },
        "& .grid-item-title": { color: theme.gray_8 },
        "& .grid-item-desc": { color: theme.gray_6 },
        "& .grid-item-img-box": {
          marginTop: 40,
          "& img": { width: 495 },
        },

        "&:nth-of-type(2)": {
          "& .grid-item-title": { color: theme.white },
          "& .grid-item-desc": { color: theme.white, opacity: 0.7 },
        },
      },
      "&.type-08": {
        minHeight: 466,
        alignItems: "center",
        paddingLeft: 20,

        "& .grid-item-img-box": {
          marginTop: "auto",
          marginRight: -1,
          marginBottom: -3,
          "& img": { width: 742 },
        },
        "& .grid-item-title-box": { margin: "auto" },
        "& .grid-item-title": { whiteSpace: "pre-line" },
        "& .grid-item-desc": {
          marginTop: 26,
          color: theme.gray_6,
          lineHeight: 1.7,
          wordBreak: "keep-all !important",
        },
      },
    },
    "&.service_item_inner_active": {
      "& .content-grid-item": {
        animation: "$functionScrollani 1s ease-in-out",
        animationFillMode: "both",
      },
    },
  },

  customers_section: {
    background: "linear-gradient(92deg, #F5F1FF 1.13%, #EDF6FC 100.47%)",
  },
  customers_section_inner: {
    minHeight: 420,
    alignItems: "center",
    justifyContent: "space-between",
    "& .sub-title": {
      color: "#9B94C3",
      fontSize: 18,
    },
    "& .title": {
      marginTop: 8,
      fontSize: 36,
      color: theme.gray_8,
      ...theme.font_bold,
    },
  },
  customers_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiDivider-root": {
      height: 182,
      width: 1,
      background: "#C8DAF9",
      margin: "0 100px",
    },
  },
  customers_box_item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",

    "& .customers_box_item_img": {
      alignItems: "center",
      justifyContent: "center",
      minHeight: 100,
    },
    "& .customers_box_item_number": {
      marginTop: 12,
      fontSize: 40,
      color: "#15336E",
      ...theme.font_bold,
    },
    "& .customers_box_item_text": {
      color: "#7888A2",
      ...typo.b1_m,
    },
  },
  use_case_section: {
    width: "100%",
  },
  use_case_section_inner: {
    paddingTop: 100,
    paddingBottom: 120,

    "& .section-title": {
      fontSize: 36,
      ...theme.font_bold,
      textAlign: "center",
    },
  },
  use_case_intro_content: {
    marginTop: 80,
    display: "flex",
    justifyContent: "space-around",
  },
  use_case_intro_item: {
    "& .icon-box": {
      margin: "0 auto",
      width: 80,
      height: 80,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 8,
      background: "#eff5fd",
    },
    "& .title": {
      marginTop: 20,
      fontSize: 20,
      ...theme.font_bold,
      textAlign: "center",
    },
    "& .desc": {
      marginTop: 8,
      color: "#747C84",
      textAlign: "center",
      letterSpacing: "-0.64px",
    },
  },
  use_case_interview_content: {
    marginTop: 120,
  },
  use_case_interview_item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 20,
    marginTop: 120,
    "&:first-child": { marginTop: 0 },
    "& .use_case_interview_item_img": {
      flex: "1 1 600px",
      maxWidth: 600,
      height: 344,
      borderRadius: 20,
      overflow: "hidden",

      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
    "& .use_case_interview_item_content": {
      flex: "1 1 589px",
      maxWidth: 589,
    },
    "& .sub-title": {
      color: service.primary_800,
      fontSize: 18,
      ...theme.font_medium,
    },
    "& .title": {
      fontSize: 32,
      color: theme.gray_8,
      ...theme.font_bold,
      letterSpacing: "-1.6px",
    },
    "& .desc": {
      marginTop: 32,
      color: "#747C84",
      letterSpacing: "-1.2px",
    },
    "& .view-more-btn": {
      marginTop: 32,
      paddingRight: 32 + 12,
      color: theme.gray_7_text,
      ...typo.b2_m,
      position: "relative",

      "&::before": {
        content: "''",
        position: "absolute",
        top: "50%",
        right: 0,
        width: 32,
        height: 32,
        transform: "translateY(-50%)",
        background: service.primary_50,
        borderRadius: "50%",
      },
      "&::after": {
        content: "''",
        position: "absolute",
        top: "50%",
        right: 8,
        width: 16,
        height: 16,
        transform: "translateY(-50%)",
        transition: "0.45s cubic-bezier(.785,.135,.15,.86)",
        backgroundColor: service.primary_600,
        maskImage: `url(${EastIcon})`,
        maskSize: "16px auto",
        maskPosition: "left top",
        maskRepeat: "repeat-x",
      },
      "&:hover::after": {
        backgroundPosition: "16px top",
        maskPosition: "16px top",
      },
    },
  },
  review_section: {
    width: "100%",
    paddingTop: 100,
    paddingBottom: 100,
    background: "#F5F8FF",
    color: theme.gray_9_title,
  },
  review_section_title: {
    ...landingTheme.typography.h3,
    ...theme.font_bold,
  },
  review_section_sub_title: {
    marginTop: 12,
    ...landingTheme.typography.body3,
    ...theme.font_medium,
    color: theme.gray_6,
  },
  review_slide_box: {
    marginTop: 50,
    marginLeft: -14,
    marginRight: -14,
    "& .slick-list": {
      padding: "10px 0",
    },
  },
  review_slide_item: {
    "&&": {
      margin: "0 14px",
      padding: 36,
      height: 360,
      display: "flex",
      flexDirection: "column",
      borderRadius: 12,
      border: 0,
      boxShadow: "1.104px 4.417px 19.876px 0 rgba(0, 0, 0, 0.04)",
    },
    "& .slide-item-title": {
      color: theme.gray_8,
      letterSpacing: -1.6,
      ...landingTheme.typography.body1,
      ...theme.font_bold,
      lineHeight: 1.5,
    },
    "& .slide-item-profile-box": {
      width: 80,
      height: 80,
      borderRadius: "50%",
      background: "#DCE6F3",
      position: "relative",
      marginLeft: "auto",
      overflow: "hidden",

      "& .profile-img": {
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
      },
    },
    "& .slide-item-text": {
      marginTop: 20,
      color: theme.gray_6,
      letterSpacing: -1.2,
      fontSize: 17,
      lineHeight: 1.5,
    },
    "& .slide-item-bottom-divider": {
      width: "100%",
      height: 1,
      marginTop: 28,
      marginBottom: 20,
      background: theme.gray_3,
    },
    "& .slide-item-bottom-box": {
      alignItems: "center",
    },
    "& .slide-item-reviewer": {
      color: theme.gray_9_title,
      ...theme.font_bold,
    },
    "& .slide-item-divider": {
      margin: "0 12px",
      width: 1,
      height: 12,
      background: theme.gray_4,
    },
    "& .slide-item-logo": {
      // height: 24,
    },
  },
  slide_arrow_button: {
    "&&": {
      width: 80,
      height: 80,
      position: "absolute",
      top: -80,
      background: theme.white,
      borderRadius: "50%",
      border: `1px solid ${theme.gray_2}`,
      color: theme.gray_4,

      "& svg": {
        width: 32,
        height: 32,
      },
      "&::before": { display: "none", visibility: "hidden" },
      "&:hover": {
        color: theme.gray_7_text,
      },
      "&.slick-prev": {
        left: "unset",
        right: 0 + 80 + 28,
      },
      "&.slick-next": {
        right: 16,
      },
    },
  },

  slide_paging: {
    margin: "0 4px",
    width: 10,
    height: 10,
    borderRadius: "50%",
    background: theme.white,
    opacity: 0.5,
    transition: "all 0.3s",
  },

  banner: { background: "#0060FF", color: theme.white },
  banner_inner: {
    padding: "87px 0",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: `url(${BannerBook}) no-repeat top 60% left -5%, url(${BannerLock}) no-repeat center right -5%`,
    "& .banner-title": {
      fontSize: 46,
      ...theme.font_bold,
      textAlign: "center",
      textTransform: "uppercase",
    },
    "& .banner-description": {
      marginTop: 12,
      ...typo.b1_r,
    },
    "& .banner-button-box": {
      marginTop: 44,
      gap: 20,
    },
    "& .banner-button": {
      padding: "0 46px",
      height: 54,
      borderRadius: 67,
      fontSize: 18,
      ...theme.font_bold,
      border: `1px solid ${alpha(theme.white, 0.4)}`,
      transition: "all 0.1s ease-in",

      "&:hover": {
        background: theme.white,
        color: theme.black,
      },
    },
  },
  [MuiTheme.breakpoints.down("lg")]: {
    review_slide_box: {
      marginLeft: -10,
      marginRight: -10,
    },
  },

  [MuiTheme.breakpoints.down(landingTheme.layout.BREAK_MD)]: {
    visual_section_inner: {
      paddingLeft: 60,
      paddingRight: 30,
      paddingTop: 100,
      paddingBottom: 40,
      position: "relative",

      "& .visual_section_inner_obj_box": {
        maxWidth: 200,
        alignSelf: "flex-end",
        transform: "translateY(20%)",
      },
    },

    service_list_item: {
      "& .service-item-title": { fontSize: 32 },
    },
    service_item_inner: {
      "& .service-item-title-box": {
        flexDirection: "column",
        alignItems: "flex-start",
      },
      "& .service-item-button-box": { flexWrap: "wrap", marginTop: 40 },
      "& .setvice-item-content-grid": { gridTemplateColumns: "1fr" },
      "& .content-grid-item.span2": { gridColumn: "1/1" },
      "& .content-grid-item:nth-of-type(2),& .content-grid-item:nth-of-type(3)":
        { animationDelay: 0 },
      "& .content-grid-item.type-02 .grid-item-img-box": {
        marginRight: 40,
        marginTop: 20,
      },
      "& .content-grid-item.type-03": {
        flexDirection: "column-reverse",
        alignItems: "flex-start",

        "& .grid-item-title-box": {
          margin: 0,
          paddingTop: 46,
          paddingLeft: 26,
        },
        "& .grid-item-img-box": {
          margin: "52px auto 0 auto",
        },
      },
      "& .content-grid-item.type-04": {
        paddingTop: 46,
        paddingBottom: 60,
        flexDirection: "column",

        "& .grid-item-img-box": { marginRight: 0, marginTop: 70 },
      },
      "& .content-grid-item.type-05": {
        "& .grid-item-img-box": { marginLeft: "auto", marginRight: 40 },
      },
      "& .content-grid-item.type-06": {
        "& .grid-item-img-box": { marginTop: 40 },
      },
      "& .content-grid-item.type-07": {
        "& .grid-item-img-box": { marginLeft: "auto", marginRight: 46 },
      },
      "& .content-grid-item.type-08": {
        padding: 0,
        flexDirection: "column",
        alignItems: "flex-start",
        "&  .grid-item-title-box": {
          paddingLeft: 46,
          paddingTop: 46,
          marginLeft: 0,
        },
        "& .grid-item-desc": { marginTop: 16 },
        "& .grid-item-img-box": {
          marginLeft: "auto",
          marginRight: 0,
          marginTop: 6,
          paddingLeft: 20,
        },
      },
    },
    customers_section: {},
    customers_section_inner: {
      padding: "80px 0",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",

      "& .sub-title": { marginBottom: 8 },
      "& .title": { fontSize: 32, whiteSpace: "normal !important" },
    },
    customers_box: {
      marginTop: 50,
      "& .MuiDivider-root": { margin: "0 60px" },
    },
    use_case_section: {},
    use_case_intro_content: {
      flexWrap: "wrap",
      maxWidth: 620,
      marginTop: 80,
      margin: "0 auto",
      justifyContent: "space-between",
    },
    use_case_intro_item: {
      width: 253,
      "&:nth-child(1)": { marginBottom: 60 },
    },
    use_case_interview_content: {},
    use_case_interview_item: {
      gap: 20,
      flexWrap: "wrap",
      "& .use_case_interview_item_img": {
        flex: 1,
        height: "auto",
        aspectRatio: "460 / 330",
        minWidth: 320,
      },
      "& .use_case_interview_item_content": {
        flex: "1",
        maxWidth: 508,
        minWidth: 360,
        order: 1,
      },
      "& .title": { whiteSpace: "pre-line", fontSize: 30, marginTop: 8 },
    },
    review_section: {},
    review_slide_item: {
      "&&": { width: 408 },
    },
    review_section_sub_title: {
      maxWidth: "calc(100% - 178px)",
    },
    banner: {
      "& $container": {
        padding: "0",
      },
    },
    banner_inner: {
      paddingLeft: 20,
      paddingRight: 20,
      background: `url(${BannerBook}) no-repeat top 60% left -5% / 230px auto , url(${BannerLock}) no-repeat center right / 230px auto`,
    },
  },

  [MuiTheme.breakpoints.down(landingTheme.layout.BREAK_SM)]: {
    visual_section: {
      padding: "0 20px",
    },
    visual_section_inner: {
      padding: 0,
      "& $container": {
        flexDirection: "column",
        padding: "0 32px",
      },
      "& .visual_section_inner_box": {
        paddingTop: 70,
      },
      "& .visual_section_inner_title": { fontSize: 28 },
      "& .visual_section_inner_desc": {
        fontSize: 14,
      },
      "& .visual_section_inner_obj_box": {
        marginTop: 16,
        transform: "translateY(20px)",
        marginBottom: 60,
        maxWidth: 140,
      },
      "& .visual_section_button": { height: 52, fontSize: 16, width: 140 },
    },
    service_item_inner: {
      "& .service-item-badge": { ...typo.b2_b },
      "&  .service-item-title": { fontSize: 24 },
      "& .grid-item-title": { fontSize: 24 },
      "& .grid-item-desc": { fontSize: 14 },
      "& .content-grid-item.type-01": {
        paddingLeft: 36,
        "& .grid-item-title-box": { paddingLeft: 0, paddingTop: 36 },
      },
      "& .content-grid-item.type-02": {
        "& .grid-item-title-box": { paddingLeft: 36, paddingTop: 36 },
        "& .grid-item-img-box": {
          marginLeft: "auto",
          marginRight: "auto",
          padding: "0 20px",
        },
      },
      "& .content-grid-item.type-03": {
        paddingLeft: 36,
        "& .grid-item-title-box": { paddingLeft: 0, paddingTop: 36 },
        "& .grid-item-desc-list": { marginTop: 30, gap: 16 },
        "& .grid-item-img-box": { marginTop: 20, marginRight: "auto" },
      },
      "& .content-grid-item.type-04": {
        padding: 0,
        minHeight: 400,
        "& .grid-item-title-box": {
          paddingLeft: 36,
          paddingTop: 36,
          paddingRight: 10,
        },
        "& .grid-item-img-box": {
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 40,
          marginBottom: 40,
          padding: "0 20px",
        },
        "& .grid-item-desc-list": {
          marginTop: 16,
          gap: 12,
          "& svg": { width: 20, height: 20 },
        },
        "& .grid-item-desc": { fontSize: 14, gap: 12 },
      },
      "& .content-grid-item.type-05": {
        "& .grid-item-title-box": { paddingLeft: 36, paddingTop: 36 },
        "& .grid-item-img-box": {
          marginTop: 60,
          marginRight: "auto",
          marginBottom: -1,
          padding: "0 20px 0 36px",
        },
      },
      "& .content-grid-item.type-06": {
        "& .grid-item-title-box": { paddingLeft: 36, paddingTop: 36 },
        "& .grid-item-img-box": {
          marginTop: 60,
          marginLeft: "auto",
          marginBottom: -1,
          paddingLeft: 36,
        },
      },
      "& .content-grid-item.type-07": {
        "& .grid-item-title-box": {
          paddingLeft: 36,
          paddingTop: 36,
          paddingBottom: 0,
          paddingRight: 0,
        },
        "& .grid-item-img-box": {
          marginTop: 42,
          marginRight: "auto",
          marginBottom: 20,
          padding: "0 36px",
        },
      },
      "& .content-grid-item.type-08": {
        minHeight: 0,
        "& .grid-item-title-box": {
          paddingLeft: 36,
          paddingTop: 36,
          paddingBottom: 0,
          paddingRight: 0,
          margin: 0,
        },
        "& .grid-item-img-box": {
          marginTop: 62,
          marginRight: "auto",
          marginBottom: 20,
          padding: "0 20px",
        },
      },
    },

    service_list_item: {
      marginBottom: 100,
      "& $container": { padding: "0" },
      "& .service-item-badge": { ...typo.b3_b },
      "& .service-item-title": { fontSize: 24 },
      "& .service-item-button": { ...typo.b3_b },
    },
    customers_section_inner: {
      padding: "60px 0",

      "& .sub-title": { fontSize: 16 },
      "& .title": { whiteSpace: "pre-line !important", fontSize: 26 },
    },
    customers_box: {
      marginTop: 32,
      flexDirection: "column",
      alignItems: "center",
      gap: 40,

      "& .MuiDivider-root": {
        ...theme.hidden,
      },
    },
    customers_box_item: {
      "& .customers_box_item_number": { marginTop: 0, fontSize: 32 },
      "& .customers_box_item_text": { ...typo.b2_m },
    },
    use_case_section_inner: {
      paddingTop: 80,
      paddingBottom: 80,

      "& .section-title": { fontSize: 28 },
    },

    use_case_intro_content: {
      marginTop: 80,
      justifyContent: "center",
      gap: "60px",
    },
    use_case_intro_item: {
      width: "100%",
      "&:nth-child(1)": { marginBottom: 0 },
    },

    use_case_interview_content: {
      marginTop: 100,
      "& .MuiDivider-root": { margin: "60px 0" },
    },
    use_case_interview_item: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: 32,

      "& .use_case_interview_item_img": {
        width: "100%",
        aspectRatio: "560 / 330",
        minWidth: "0",
      },
      "& .use_case_interview_item_content": {
        width: "100%",
        maxWidth: "100%",
        minWidth: 0,
        "& .view-more-btn": { fontSize: 14 },
      },
      "& .sub-title": { fontSize: 16 },
      "& .title": { whiteSpace: "normal", fontSize: 24 },
      "& .desc": { marginTop: 28, fontSize: 14 },
    },
    review_section: {
      padding: "60px 0",
    },
    review_section_title: { fontSize: 28 },
    review_section_sub_title: { fontSize: 16, maxWidth: "100%" },
    review_slide_box: {
      marginTop: 80,
    },
    review_slide_item: {
      "&&": {
        width: 408,
        maxWidth: "calc(100vw - 48px)",
        minHeight: 360,
        height: "auto",
      },
      "& .slide-item-title": { marginRight: 8 },
      "& .slide-item-profile-box": { flexShrink: 0 },
    },
    slide_arrow_button: {
      "&&": { top: -40, width: 40, height: 40 },
      "&&.slick-prev": {
        left: 16,
        right: "unset",
      },
      "&&.slick-next": {
        left: 68,
        right: "unset",
      },
    },
    banner_inner: {
      padding: "60px 20px",
      background: "#0060FF",

      "& .banner-title": { fontSize: 28 },
      "& .banner-description": { ...typo.b2_r, whiteSpace: "pre-line" },
      "& .banner-button-box": {
        marginTop: 40,
        flexWrap: "wrap",
        justifyContent: "center",
        gap: 8,
      },
      "& .banner-button": {
        height: 46,
        padding: "0 40px",
        fontSize: 16,
      },
    },
  },
}));

export default useHomeStyles;
